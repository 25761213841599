import menu from './ko/menu'
import home from './ko/home'
import chat from './ko/chat'

const lang = {
	'app.switch.language.tips': '{{msg}}을(를) 성공적으로 전환했습니다.',
	'app.language': '언어',
	'app.loading': '로딩중...',
	'app.address.error.tips': '주소 오류',

	'connect.sign': '징후...',
	'connect.btn': '연결',
	'connect.logout': '로그아웃',
	'connect.sign.error': '서명 검증 실패',

	'layout.menu.stake': '스테이크',
	'layout.menu.withdraw': '인출',
	'layout.menu.team': '생태',
	'layout.menu.share': '공유',

	...menu,
	...home,
	...chat,
}

export default lang
