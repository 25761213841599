'use client'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export type State = {
	isSign: boolean
}
const initialState: State = {
	isSign: false,
}

export type Actions = {
	setIsSign: (isSign: boolean) => void
	reset: () => void
}

const useWalletStore = create(
	persist<State & Actions>(
		(set, get) => ({
			...initialState,
			setIsSign: isSign => set({ isSign }),
			reset: () => set(initialState),
		}),
		{
			name: 'ConnectStroe',
			storage: createJSONStorage(() => sessionStorage),
		},
	),
)

export default useWalletStore
