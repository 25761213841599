const home = {
	'home.usdt.title': 'Tether USD',
	'home.usdt.max': 'MAX',
	'home.usdt.balance': 'Your Balance:',
	'home.usdt.total': 'Total Staking:',
	'home.btn.approve': 'Approve',
	'home.btn.stake': 'Stake',

	'home.withdraw.title': 'Withdrawable:',
	'home.withdraw.2': 'Withdraw',
	'home.withdraw.earnings': 'Passive income:',
	'home.withdraw.amount': 'Community benefits:',
	'home.withdraw.btn1': 'Get Reward',
	'home.withdraw.btn2': 'Restake',

	'home.team.title': 'Total Community Staking',
	'home.team.1': 'Address:',
	'home.team.upper': 'Referrer:',
	'home.team.address.placeholder': 'Please enter the user address',
	'home.team.address.s': 'Bound',
	'home.team.leavel': 'Level:',
	'home.team.bind': 'Bind Relation',

	'home.invite.title': 'Invite Link',
	'home.invite.copy': 'Copy Link',
	'home.invite.tip': 'Please activate your account first',

	'home.message.tip0': 'Received successfully',
	'home.message.tip1': 'Authorization successful',
	'home.message.tip2': 'Stake successful',
	'home.message.tip3': 'Minimum pledge amount: {{msg}} USDT',
	'home.message.tip4': 'Current account balance: {{msg}} USDT',
	'home.message.tip5': 'Copy successful',
	'home.message.tip6': 'Cannot bind your own address',
	'home.message.tip7': 'Binding successful',
	'home.message.tip8': 'Redemption successful',
	'home.message.tip9': 'The maximum amount that can be redeemed in the current account: {{msg}} USDT',
}

export default home
