import { Button } from '@nextui-org/react'

import { ReactComponent as LOGO } from '@/assets/lgoo.svg'
import { formatStrAddress, verifyMessage } from '@/utils'

import BaseModal from '@/components/Modal'
import { useEffect, useRef } from 'react'
import { nanoid } from 'nanoid'
import toast from 'react-hot-toast'
import useWalletStore from '@/lib/store/useWalletStore'
import { useTranslation } from 'react-i18next'

import { useDisconnect, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { ethers } from 'ethers'

export default function ConnectModale({ isNoPage = false }: { isNoPage?: boolean }) {
	const { t } = useTranslation()
	const { isConnected, address } = useWeb3ModalAccount()
	const { disconnect } = useDisconnect()
	const { walletProvider } = useWeb3ModalProvider()
	const { open } = useWeb3Modal()

	const BaseModalRef = useRef<any>(null)
	const isSign = useWalletStore(store => store.isSign)

	useEffect(() => {
		if (isConnected) {
			handleSign()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConnected, address])

	const handleSign = async () => {
		const uid = nanoid(24)
		const isSignature = window.sessionStorage.getItem(address as string) ?? undefined
		if (isSignature === 'true') return
		useWalletStore.getState().setIsSign(true)
		if (!address || !walletProvider) {
			useWalletStore.getState().setIsSign(false)
			return
		}
		try {
			const web3 = new ethers.BrowserProvider(walletProvider)
			const signer = await web3.getSigner()
			const signature = await signer?.signMessage(uid.toString())
			const isTrue = await verifyMessage(uid, address, signature)
			if (!isTrue) {
				useWalletStore.getState().setIsSign(false)
				disconnect()
				toast.error(t('connect.sign.error'))
			} else {
				window.sessionStorage.setItem(address as string, 'true')
				useWalletStore.getState().setIsSign(false)
			}
		} catch (error: any) {
			console.log('error', error)
			toast.error(t('connect.sign.error'))
			disconnect()
			useWalletStore.getState().setIsSign(false)
		}
	}

	if (isNoPage) return <></>

	return (
		<>
			{!isConnected && (
				<Button onClick={() => open()} className="tail-bg relative z-[99] h-[1.875rem]" radius="full">
					<span className="text-sm font-normal text-white">{t('connect.btn')}</span>
				</Button>
			)}
			{isConnected && (
				<Button
					onClick={() => {
						BaseModalRef.current?.onOpen()
					}}
					className="tail-bg relative z-[99] h-[1.875rem] min-w-[8.125rem]"
					radius="full"
				>
					{isSign ? (
						<span className="text-sm font-normal text-[var(--color-1)]">{t('connect.sign')}</span>
					) : (
						<>
							<LOGO />
							<span className="text-sm font-normal text-white">{formatStrAddress(4, 4, address ?? '')}</span>
						</>
					)}
				</Button>
			)}
			<BaseModal ref={BaseModalRef} bodyClassName="min-h-[8.75rem] px-10 py-12 justify-center items-center">
				<Button
					onClick={() => {
						disconnect()
						BaseModalRef.current?.onClose()
						window.sessionStorage.removeItem(address as string)
					}}
					className="tail-btn-one"
				>
					<span className="text-base font-bold text-white">{t('connect.logout')}</span>
				</Button>
			</BaseModal>
		</>
	)
}
