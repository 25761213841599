const chat = {
	'chat.input.placeholder1': 'What do you want to know?',
	'chat.input.placeholder2': 'Waiting for the last answer',
	'chat.submit.btn': 'Send',
	'chat.submit.on.address': 'Unactivated account, unable to chat',
	'chat.wallet.btn.title': 'Unlinked wallet, unable to chat',
	'chat.data.params.msg1': 'System error',
	'chat.data.params.msg2': 'User is not activated',
	'chat.data.params.msg3': 'Only 10 accesses per day',
	'chat.data.params.msg1001': 'Invalid json',
	'chat.data.params.msg.other': 'Request failed, please try again',
}

export default chat
