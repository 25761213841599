import { ReactComponent as NODATASVG } from '@/assets/404.svg'
import { Button } from '@nextui-org/react'
import { Link } from 'react-router-dom'

export default function NoDatePage() {
	return (
		<div className="flex flex-col items-center justify-center gap-8 pt-4">
			<NODATASVG className="h-auto max-w-[60%]" />
			<Button as={Link} to="/home" className="w-4/5 bg-[var(--theme)] text-sm font-bold uppercase text-white">
				Go To Home
			</Button>
		</div>
	)
}
