import { getSubgraphsRequest } from '@/graphql'

export interface ICurrentTotalCount {
	managerWithdrawnAmount: string
	profitTotalAmount: string
	stakeAmount: string
	withdrawnAmount: string
}

export const CurrentUserInfoInit: ICurrentTotalCount = {
	stakeAmount: '0',
	withdrawnAmount: '0',
	managerWithdrawnAmount: '0',
	profitTotalAmount: '0',
}

// 获取当日的统计的数据
export const serverQlCurrentTotalCount = async (id: string): Promise<ICurrentTotalCount> =>
	new Promise(async (resolve, reject) => {
		let query = `
    query MyQuery($id: ID) {
  dayCounts(where: {id: $id}) {
    id
    managerWithdrawnAmount
    stakeAmount
    profitTotalAmount
    withdrawnAmount
    crateAt
  }
}
  `
		try {
			const { data } = await getSubgraphsRequest(query, {
				id: id,
			})
			console.log('data', data)
			if (data?.dayCounts && data.dayCounts.length > 0) {
				resolve(data.dayCounts[0])
			} else {
				resolve(CurrentUserInfoInit)
			}
		} catch (error) {
			resolve(CurrentUserInfoInit)
		}
	})
