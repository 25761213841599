import Home from '@/pages/Home'
// import Chat from '@/pages/Chat'
import No404 from '@/pages/404'
import Share from '@/pages/Share'
import Team from '@/pages/Team'
import WithDraw from '@/pages/Withdraw'
import Layout from '@/layout'
import Count from '@/pages/Count'
// import Options from '@/pages/Options'
import { Navigate, createHashRouter } from 'react-router-dom'
import type { RouteObject } from 'react-router-dom'

/**
 * @class 路由定义
 * @param createHashRouter /#/home
 * @param createBrowserRouter /home
 */

const Router: RouteObject[] = [
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				path: '/',
				element: <Navigate to="/home" />,
			},
			{
				path: '/rqudgaby',
				element: <Count />,
			},
			{
				path: '/home',
				element: <Home />,
			},
			// {
			// 	path: '/chat',
			// 	element: <Chat />,
			// },
			{
				path: '/share',
				element: <Share />,
			},
			{
				path: '/team',
				element: <Team />,
			},
			{
				path: '/withdraw',
				element: <WithDraw />,
			},
			// {
			// 	path: '/option',
			// 	element: <Options />
			// },
			{
				path: '/404',
				element: <No404 />,
			},
			{
				path: '*',
				element: <No404 />,
			},
		],
	},
	{
		path: '/404',
		element: <No404 />,
	},
	{
		path: '*',
		element: <No404 />,
	},
]

export default createHashRouter(Router)
