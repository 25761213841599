import { ISendTransation } from './type.d'
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { getGasPrice, waitForTransaction } from '../web3/multicall'
import BigNumber from 'bignumber.js'
import { DEFAULT_CHAINID } from '~/config/*'

import { sepolia, bsc } from 'viem/chains'
import { REACT_APP_ENV } from '@/utils'
import { createWalletClient, custom } from 'viem'
import ToastError from '../web3/toastError'

export const useSendTransaction = () => {
	const { walletProvider } = useWeb3ModalProvider()
	const { address, chainId: linkChainId } = useWeb3ModalAccount()
	const chainId = DEFAULT_CHAINID

	/**
	 * @class onSendTransaction 合约交易方法
	 */
	const onSendTransaction = ({ isLocal = 'en', ...roots }: ISendTransation) => {
		return new Promise(async (resolve, reject) => {
			if (!address || !walletProvider) {
				reject(new Error('on Login'))
				return
			}
			const walletClient = createWalletClient({
				chain: REACT_APP_ENV === 'dev' ? sepolia : bsc,
				transport: custom(walletProvider),
			})

			if (chainId !== linkChainId) {
				try {
					REACT_APP_ENV === 'dev'
						? await walletClient.switchChain({ id: sepolia.id })
						: await walletClient.addChain({ chain: bsc })
				} catch (error) {
					console.log('network', error)
					reject('User rejected the request.')
				}
			}
			const tx: any = {
				account: address,
				to: roots.to,
				data: roots.data,
			}
			if (roots.value) tx.value = roots.value
			const gasPrice = await getGasPrice(chainId as number)
			console.log('gasPrice', gasPrice)
			const gasPriceTwo = new BigNumber(Number(gasPrice)).times(2).toString()
			// gas Price tiems 2
			try {
				const hash = await walletClient.sendTransaction({
					...tx,
					gasPrice: new BigNumber(Number(gasPriceTwo)),
					connector: walletProvider,
				})
				if (hash && roots.onTransactionHash) roots.onTransactionHash(hash)
				const result = await waitForTransaction(chainId, hash)
				console.log('result', result)
				resolve(result)
			} catch (error: any) {
				console.log('er', error)
				reject(ToastError(error))
			}
		})
	}

	return { onSendTransaction }
}
