const chat = {
	'chat.input.placeholder1': '何を知りたいですか? ',
	'chat.input.placeholder2': '最後の回答を待っています',
	'chat.submit.btn': '送信',
	'chat.submit.on.address': 'アカウントがアクティブ化されていないため、チャットできません',
	'chat.wallet.btn.title': 'ウォレットがリンクされていないためチャットできません',
	'chat.data.params.msg1': 'システム エラー',
	'chat.data.params.msg2': 'ユーザーはアクティブ化されていません',
	'chat.data.params.msg3': '1 日に 10 回のみアクセス可能',
	'chat.data.params.msg1001': '無効な json',
	'chat.data.params.msg.other': 'リクエストは失敗しました。もう一度送信してください',
}

export default chat
