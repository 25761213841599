const chat = {
	'chat.input.placeholder1': '您有什麼想知道的？ ',
	'chat.input.placeholder2': '等待上次回答中',
	'chat.submit.btn': '發送',
	'chat.submit.on.address': '未啟動帳戶，無法聊天',
	'chat.wallet.btn.title': '未連結錢包，無法聊天',
	'chat.data.params.msg1': '系統錯誤',
	'chat.data.params.msg2': '使用者未啟動',
	'chat.data.params.msg3': '每天只能訪問10次',
	'chat.data.params.msg1001': '無效的json',
	'chat.data.params.msg.other': '請求失敗，請重試發送',
}

export default chat
