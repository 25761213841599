import { contracts, DEFAULT_CHAINID, multicall3, SUPPER_CHAINS, switchNetwork } from '~/config/*'

const getChainConfig = (chainId?: any) => {
	if (chainId && contracts[chainId]) {
		return {
			contracts: contracts[chainId],
			multicall3: multicall3[chainId],
			chainIdBlockExplorerUrl: switchNetwork[chainId].blockExplorerUrls,
			isExist: true,
			chain: SUPPER_CHAINS[chainId],
		}
	}

	return {
		contracts: contracts[DEFAULT_CHAINID],
		multicall3: multicall3[DEFAULT_CHAINID],
		chainIdBlockExplorerUrl: switchNetwork[DEFAULT_CHAINID].blockExplorerUrls,
		isExist: false,
		chain: SUPPER_CHAINS[DEFAULT_CHAINID],
	}
}

export default getChainConfig
