const menu = {
	'menu.home': '홈',
	'menu.contract': '계약',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': '옵션 계약',
	'menu.option.ai': 'AI 고주파 중재',
	'menu.option.qr': '양적 로봇',
	'menu.option.ai.mall': 'AI 스마트 몰',
}

export default menu
