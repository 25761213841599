const menu = {
	'menu.home': 'ホーム',
	'menu.contract': '契約',
	'menu.chatgpt': 'ChatGPT',
	'menu.option.contract': 'オプション契約',
	'menu.option.ai': 'AI高頻度アービトラージ',
	'menu.option.qr': '定量ロボット',
	'menu.option.ai.mall': 'AIスマートモール',
}

export default menu
